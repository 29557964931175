import React from 'react';
import Img from 'gatsby-image';
import { useSelector } from 'react-redux';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getImages } from '../../utils/common';

import Layout from '../../components/Layout';
import Slider from '../../components/Slider';
import Banner from '../../components/Home/Banner';
import Arrow from '../../components/Slider/Arrow';
import Link from '../../components/Link';
import Tab from '../../components/Home/Tab';
import Menu from '../../components/Home/Menu';
import Card from '../../components/Home/Card';

import * as styles from './MenHome.module.scss';
import ImgMenArrows from '../../assets/images/global/men-arrows.png';

import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const ArrowStyled = styled.div`
  background: url(${ImgMenArrows}) no-repeat;
  height: 100%;
  width: 100%;
`;

const PrevArrow = styled(ArrowStyled)`
  &:hover {
    background-position-y: -44px;
  }
`;

const NextArrow = styled(ArrowStyled)`
  background-position-y: -88px;

  &:hover {
    background-position-y: -132px;
  }
`;

const MenHome = ({ pageContext, data }) => {
  const { locale } = pageContext;
  const { isMobileView } = useSelector((state) => state.common);
  const { allBanner, allFile, allMenu } = data;
  const images = getImages(allFile.nodes);
  const { t,i18n } = useTranslation();

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 7000,
    prevArrow: (
      <Arrow type="prev">
        <PrevArrow />
      </Arrow>
    ),
    nextArrow: (
      <Arrow type="next">
        <NextArrow />
      </Arrow>
    ),
    customPaging: (i) => {
      return <div>{i + 1}</div>;
    },
    dotsClass: styles.Dots,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          customPaging: () => {
            return <div className={styles.Dot}></div>;
          },
        },
      },
    ],
  };

  const handleSliderClick = (image,url) => {
    //console.log('handleSliderClick');

    const src = image.src;
    const lastPart = src.split('/').pop();

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'slider_button_click',
      eventAction: 'select::slider navigation',
      eventCategory: 'homepage',
      eventLabel: `${lastPart}::${url}`,
      cta_name: lastPart,
      link_url: trackingLinkHelper(url),
      site_language: i18n.language,
      page_category: 'homepage',
      brand: 'BIO',
      country: 'hk',
   }); 
  };

  return (
    <Layout
      padding={false}
      seoSettings={{ title: t('home_page') }}
      hasBreadcrumb={false}
    >
      {isMobileView && <Tab gender={'men'} />}
      <Slider settings={sliderSettings} className={styles.Slider}>
        {allBanner.nodes.map((item) => {
          return (
            <Banner
              key={item.id}
              path={item.content.url}
              image={item.content.remoteImage.childImageSharp.fluid}
              onClick={()=>{handleSliderClick(item.content.remoteImage.childImageSharp.fluid,item.content.url)}}
            />
          );
        })}
      </Slider>
      {isMobileView && <Menu list={allMenu.nodes[0].types} />}
      {!isMobileView && (
        <div
          className={`row no-gutters justify-content-between`}
          style={{ borderBottom: '2px solid #555', overflow: 'hidden' }}
        >
          <div style={{ width: 585, borderTop: '1px solid #555' }}>
            <Card
              bgImage={locale === 'zh'
                ? images['man_banner_chi']
                : images['man_banner_eng']}
              button={'explore'}
              buttonAlign='right'
              path={'/men/category/face-care'}
              bgWidth='100%'
              alt={locale === 'zh'
                ? '男士護膚系列'
                : `Men's skinecare`}
            />
            <div
              className={`row no-gutters`}
              style={{ borderBottom: '1px solid #555' }}
            >
              <div className='col-6'>
                <Card
                  bgImage={locale === 'zh'
                    ? images['img_homme_limited_offers_banner_2022_cn']
                    : images['img_homme_limited_offers_banner_2022_eng']}
                  button={'discover_more'}
                  path={`/men/product/196`}
                  bgWidth='100%'
                  alt={locale === 'zh'
                    ? '熱賣產品'
                    : `In the spot`}
                />
              </div>
              <div className='col-6'>
                <Card
                  bgImage={locale === 'zh'
                    ? images['universe_chi']
                    : images['universe_eng']}
                  button={'discover_more'}
                  path={`/about-biotherm`}
                  bgWidth='100%'
                  alt={locale === 'zh'
                    ? '男士產品'
                    : `Men's product`}
                />
              </div>
            </div>
            {/* <div className={`row no-gutters`}>
              <div style={{ width: 197 }}>
                <Card to="/men/top-rated">
                  <Img fluid={images['top10']} />
                </Card>
              </div>
            </div> */}
          </div>
          <div style={{ width: 398 }}>
            <div style={{ borderLeft: '1px solid #555' }}>
              <Img
                fluid={
                  locale === 'zh'
                    ? images['n1_banner_chi']
                    : images['n1_banner_eng']
                }
              />
            </div>
            <iframe
              title="facebook"
              className={styles.Facebook}
              src="//www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2Fbiotherm.hongkong&amp;width=391&amp;height=593&amp;show_faces=false&amp;colorscheme=light&amp;stream=true&amp;border_color=%23ffffff&amp;header=true&amp;appId=333750476729210"
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default MenHome;

export const pageQuery = graphql`
  query($locale: String, $gender: String) {
    allBanner(sort: { fields: sequence }, filter: { gender: { eq: $gender } }) {
      nodes {
        id
        unique_id
        content(locale: $locale) {
          url
          image
          remoteImage {
            childImageSharp {
              fluid(quality: 100,maxWidth:980) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allFile(filter: { relativeDirectory: { eq: "images/menHome" } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    allMenu(filter: { slug: { eq: $gender } }) {
      nodes {
        slug
        types {
          id
          slug
          localizations {
            en {
              name
            }
            zh {
              name
            }
          }
          categories {
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
          needs {
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
          series {
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
          }
        }
      }
    }
  }
`;
